import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import { usePresence } from "./presence-context";
import { usePresenceWithCursors } from "./use-cursors";
import { Pointer } from "./cursor";

// Global interface declaration
declare global {
  interface Window {
    ethereum?: any;
  }
}

// Utility functions
function isLightColor(color: string): boolean {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 200;
}

// Styles
const styles: Record<string, React.CSSProperties> = {
  container: {
    boxSizing: "border-box",
    position: "fixed",
    padding: "8px",
    borderRadius: "24px",
    minWidth: "2.5em",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "8px",
    fontFamily:
      'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontWeight: 320,
    zIndex: 1000,
    boxShadow: '0 2px 2px rgba(0,0,0,0.2)'
  },
  input: {
    boxSizing: "border-box",
    padding: "0px 4px 0px 4px",
    margin: "0px",
    fontSize: "16px",
    lineHeight: 1,
    whiteSpace: "nowrap",
  },
  button: {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    border: '2px solid white',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    boxSizing: 'border-box',
  },
  colorPickerContainer: {
    width: '44px',
    height: '44px',
    position: 'relative',
  },
  colorButton: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid white',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
  },
  colorInput: {
    position: 'absolute',
    top: '-5px',
    left: '-5px',
    width: 'calc(100% + 10px)',
    height: 'calc(100% + 10px)',
    border: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  chatBubbleContainer: {
    position: "absolute",
    zIndex: 1000,
    pointerEvents: "none",
  },
  pointerContainer: {
    // This will be dynamically set in the component
  },
  ensNameContainer: {
    position: 'absolute',
    top: '-56px',
    left: '12px',
    fontSize: '10px',
    padding: '4px 5px', 
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    fontFamily:
      'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  verificationIcon: {
    marginLeft: '4px',
    width: '14px',
    height: '14px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  verificationCheckmark: {
    position: 'absolute',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  simpleVerificationIcon: {
    marginLeft: '4px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '8px',
    fontWeight: 'bold',
  },
  controlButtonsContainer: {
    position: 'fixed',
    bottom: '24px',
    right: '32px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    zIndex: 1001,
  },
};

// Sub-components
const ColorPicker = ({ color, onChange }: { color: string; onChange: (color: string) => void }) => {
  return (
    <div style={styles.colorButton}>
      <input
        type="color"
        value={color}
        onChange={(e) => onChange(e.target.value)}
        style={styles.colorInput}
      />
    </div>
  );
};

const UserIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
  </svg>
);

const EyeIcon = ({ isOpen }: { isOpen: boolean }) => (
  <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
    {isOpen ? (
      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
    ) : (
      <path d="M3 12h18" strokeWidth="2" stroke="currentColor" fill="none"/>
    )}
  </svg>
);

// Main Chat component
export default function Chat({ isQuietMode, toggleQuietMode }: { isQuietMode: boolean; toggleQuietMode: () => void }) {
  // State declarations
  const [listening, setListening] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [showCursor, setShowCursor] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [ensName, setEnsName] = useState<string | null>(null);
  const [isMovingRight, setIsMovingRight] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [shouldPersist, setShouldPersist] = useState(false);

  // Hooks
  const { updatePresence, showCTA, myself, myId } = usePresence((state) => ({
    updatePresence: state.updatePresence,
    showCTA: Array.from(state.otherUsers.values()).some(user => user.presence?.message),
    myself: state.myself,
    myId: state.myId,
  }));

  const myCursor = usePresenceWithCursors((state) => state.myself?.presence.cursor);
  const prevCursorRef = useRef(myCursor);

  // Derived values
  const randomSeed = myId?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) || 0;
  const svgIndex = randomSeed % 5;
  const currentColor = myself?.presence.color || "#000000";
  const isLight = isLightColor(currentColor);

  // Effects
  useEffect(() => {
    if (myCursor && prevCursorRef.current) {
      if (myCursor.x !== prevCursorRef.current.x) {
        setIsMovingRight(myCursor.x > prevCursorRef.current.x);
      }
    }
    prevCursorRef.current = myCursor;
  }, [myCursor]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updatePresence({ 
      message: message.length > 0 ? message : null,
      ensName: ensName,
    });
    console.log('Presence updated in useEffect. ENS name:', ensName);
  }, [message, ensName, updatePresence]);

  useEffect(() => {
    const resetTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (!shouldPersist) {
        const newTimeoutId = setTimeout(() => {
          setListening(false);
          setMessage("");
          setShowInput(false);
        }, 10000);
        setTimeoutId(newTimeoutId);
      }
    };

    if (message || listening) {
      resetTimeout();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [message, listening, shouldPersist]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!listening) {
        if (event.key === "/") {
          setMessage("");
          setListening(true);
          setShowInput(true);
          setShouldPersist(false);
          event.preventDefault();
        } else if (event.key === "Escape" && message) {
          setMessage("");
          setShowInput(false);
          setShouldPersist(false);
          event.preventDefault();
        }
      } else {
        if (!event.metaKey && !event.ctrlKey && !event.altKey) {
          if (event.key === "Enter") {
            setListening(false);
            setShouldPersist(true);
          } else if (event.key === "Escape") {
            setListening(false);
            setMessage("");
            setShowInput(false);
            setShouldPersist(false);
          } else if (event.key === "Backspace") {
            setMessage((prev) => prev.slice(0, -1));
          } else if (event.key.length === 1) {
            setMessage((prev) => {
              return prev.length < 64 ? prev + event.key : prev;
            });
          }

          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [listening, message]);

  // Helper functions
  const handleColorChange = (color: string) => {
    updatePresence({ color });
  };

  const connectWallet = async () => {
    if (typeof window.ethereum === 'undefined') {
      console.log('Please install a signer browser extension!');
      alert('Please install an ENS compatible signer browser extension like frame, rainbow, or metamask to display your verified ENS/DNS name in chat');
      return;
    }

    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log('Accounts:', accounts);

      if (accounts.length === 0) {
        console.log('No accounts found');
        alert('No accounts found. Please make sure your wallet is unlocked and try again.');
        return;
      }

      const provider = new ethers.BrowserProvider(window.ethereum);
      console.log('Provider created');

      const signer = await provider.getSigner();
      console.log('Signer obtained');

      const address = await signer.getAddress();
      console.log('Address:', address);

      // Resolve ENS name with CCIP support
      let ensName = null;
      try {
        console.log('Attempting to resolve ENS name with CCIP...');
        const ensRegistryAddress = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e';
        const ensRegistryAbi = ['function resolver(bytes32 node) external view returns (address)'];
        const ensRegistry = new ethers.Contract(ensRegistryAddress, ensRegistryAbi, provider);
        
        const namehash = ethers.namehash(address.toLowerCase().substring(2) + '.addr.reverse');
        const resolverAddress = await ensRegistry.resolver(namehash);
        
        if (resolverAddress !== ethers.ZeroAddress) {
          const resolverAbi = ['function name(bytes32 node) external view returns (string)'];
          const resolver = new ethers.Contract(resolverAddress, resolverAbi, provider);
          ensName = await resolver.name(namehash);
        }

        console.log('Raw ENS result:', ensName);
        
        if (!ensName) {
          console.log('No ENS name found for this address');
          alert('No ENS name found for your address. Please set up an onchain or offchain ENS name to use this feature.');
          return; // Exit the function early
        }
        
        console.log('Final ENS name:', ensName);
      } catch (ensError) {
        console.error('Error resolving ENS name:', ensError);
        alert('Error resolving ENS name. Please try again or make sure you have an ENS name set up.');
        return; // Exit the function early
      }

      // If we've reached this point, we have a valid ENS name
      const message = `Verify ENS/DNS ownership to display ${ensName} as your name in chat ฅ^•ﻌ•^ฅ\n\nThis homepage will never trigger any blockchain transactions and no information is stored.`;
      console.log('Message to sign:', message);

      try {
        console.log('Requesting signature...');
        const signature = await signer.signMessage(message);
        console.log('Signature obtained:', signature);

        console.log('Verifying signature...');
        const recoveredAddress = ethers.verifyMessage(message, signature);
        console.log('Recovered address:', recoveredAddress);

        if (recoveredAddress.toLowerCase() === address.toLowerCase()) {
          console.log('Signature verified successfully');
          console.log('Setting ENS name:', ensName);
          setEnsName(ensName);
          setIsConnected(true);
          updatePresence({ ensName: ensName });
          console.log('Presence updated with ENS name:', ensName);
        } else {
          console.error('Signature verification failed');
          alert('Failed to verify wallet ownership');
        }
      } catch (signError) {
        console.error('Error during signing:', signError);
        alert('Error during signature process: ' + (signError instanceof Error ? signError.message : String(signError)));
      }
    } catch (error) {
      console.error('Error in connectWallet:', error);
      alert('Failed to connect wallet: ' + (error instanceof Error ? error.message : String(error)));
    }
  };

  const renderChatContent = () => {
    if (message) {
      return message;
    } else if (listening) {
      return showCursor ? "..." : "\u00A0".repeat(3);
    } else if (showCTA && !isQuietMode) {
      return "type / to reply";
    } else {
      return null;
    }
  };

  // Render
  return (
    <>
      {(showInput || message || (showCTA && !isQuietMode)) && myCursor && (
        <div
          style={{
            ...styles.chatBubbleContainer,
            left: `${myCursor.x}px`,
            top: `${myCursor.y}px`,
          }}
        >
          <div style={{
            ...styles.pointerContainer,
            transform: isMovingRight ? 'scaleX(-1)' : 'none'
          }}>
            <Pointer fill={currentColor} svgIndex={svgIndex} />
          </div>
          {myself?.presence.ensName && (
            <div style={{
              ...styles.ensNameContainer,
              color: isLight ? "black" : "white",
              backgroundColor: currentColor,
            }}>
              <span>{myself.presence.ensName}</span>
              {myself.presence.ensName === 'elle.email' ? (
                <span style={styles.verificationIcon}>
                  <svg viewBox="0 0 294.996 294.996" width="14" height="14">
                    <path d="M280.977,118.478c-13.619-10.807-20.563-27.57-18.574-44.845c1.3-11.3-2.566-22.393-10.607-30.432
                    c-8.044-8.043-19.136-11.909-30.434-10.607c-17.281,1.986-34.037-4.954-44.844-18.573C169.449,5.11,158.872,0,147.499,0
                    c-11.374,0-21.951,5.11-29.021,14.02c-10.807,13.618-27.564,20.56-44.841,18.575c-11.3-1.305-22.393,2.563-30.435,10.605
                    c-8.043,8.04-11.909,19.133-10.609,30.435c1.989,17.272-4.954,34.035-18.576,44.844C5.11,125.549,0,136.126,0,147.498
                    s5.109,21.949,14.019,29.021c13.62,10.808,20.563,27.57,18.574,44.845c-1.3,11.3,2.566,22.393,10.607,30.432
                    c8.044,8.043,19.145,11.911,30.434,10.607c17.274-1.988,34.037,4.954,44.844,18.573c7.069,8.91,17.646,14.021,29.021,14.021
                    c11.373,0,21.95-5.11,29.02-14.02c10.808-13.618,27.565-20.559,44.841-18.575c11.301,1.299,22.393-2.563,30.435-10.605
                    c8.043-8.04,11.909-19.133,10.609-30.434c-1.989-17.273,4.955-34.037,18.576-44.845c8.907-7.07,14.017-17.647,14.017-29.02
                    S289.886,125.549,280.977,118.478z" fill={isLight ? "black" : "white"} />
                  </svg>
                  <span style={{
                    ...styles.verificationCheckmark,
                    color: currentColor,
                  }}>
                    ✓
                  </span>
                </span>
              ) : (
                <span style={{
                  ...styles.simpleVerificationIcon,
                  backgroundColor: isLight ? "black" : "white",
                  color: currentColor,
                }}>
                  ✓
                </span>
              )}
            </div>
          )}
          <div style={{ 
            ...styles.container, 
            position: 'absolute',
            left: '12px',
            top: '-30px',
            backgroundColor: currentColor,
            color: isLight ? "black" : "white"
          }}>
            <div style={styles.input}>
              {renderChatContent()}
            </div>
          </div>
        </div>
      )}
      {(showInput || message) && (
        <div style={styles.controlButtonsContainer}>
          <div style={styles.colorPickerContainer}>
            <ColorPicker
              color={currentColor}
              onChange={handleColorChange}
            />
          </div>
          <button 
            style={{
              ...styles.button,
              backgroundColor: isConnected ? '#000' : '#ccc',
            }} 
            onClick={connectWallet}
          >
            <UserIcon />
          </button>
          <button
            style={{
              ...styles.button,
              backgroundColor: isQuietMode ? '#000' : '#ccc',
            }}
            onClick={toggleQuietMode}
          >
            <EyeIcon isOpen={!isQuietMode} />
          </button>
        </div>
      )}
    </>
  );
}
